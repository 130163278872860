import {Handle, Position} from "reactflow";
import NodeStatus from "./NodeComponents/NodeStatus";
import NodeName from "./NodeComponents/NodeName";
import React, {FC} from "react";
import SuperHeader from "./NodeComponents/SuperHeader";
import HostNodeInterface from "../../Interfaces/HostNodeInterface";
import { isHostNodeInterface} from "../../Helpers/interface_helpers";
import ButtonSection from "./ButtonSection";
import BuildNodeInterface from "../../Interfaces/BuildNodeInterface";
import {MdBuild} from "react-icons/md";
import {GrHost} from "react-icons/gr";

const SystemNode: FC<{
    data: HostNodeInterface | BuildNodeInterface;
}> = React.memo(({data}) => {
    const is_host = isHostNodeInterface(data);
    return (
        <div className={"node " + (data.node_selected ? "node-selected" : "")}>
            <SuperHeader
                application={data.data.application}
                systemName={is_host ? data.data.systemName : undefined}
            />
            <div
                style={{
                    // padding: "auto",
                    display: "flex",
                    flexDirection: "column",
                    flex: 1,
                    // justifyContent: "space-between",
                    width: "95%",
                    paddingTop: 5,
                    paddingBottom: 5,
                }}
            >
                <div style={{flex: 1}}>
                    <NodeName
                        variant={data.data.variant}
                        environment={data.data.environment}
                        nodeId={data.data.nodeId}
                        pipelineUrl={"pipelineUrl" in data.data ? data.data?.pipelineUrl : null}
                    />
                </div>
                <div style={{width: "100%", flex: 1}}>
                    <NodeStatus
                        data={data.data}
                        last_image={is_host ? data.last_image : true}
                        older_than_24h={is_host ? data.older_than_24h : false}
                        missing_parent={is_host ? data.missing_parent : false}
                    />
                </div>

                <div style={{flex: 1}}>
                    <ButtonSection data={data}/>
                </div>
            </div>
            {isHostNodeInterface(data) ? (
                <div
                    className={"abs node-footer right0"}
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                    }}
                >
                    {data.data.itemType}

                    <GrHost size={"1rem"}/>
                </div>
            ) : (
                <div
                    className={"abs node-footer left0"}
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                    }}
                >
                    <MdBuild size={"1rem"}/>
                    {data.data.itemType}
                </div>
            )}

            {/*{isHostNodeInterface(data) ? (*/}
            <Handle
                type="target"
                position={Position.Left}
                style={{background: "#555"}}
                isConnectable={false}
            />
            {/*) : (*/}
            {/*  <></>*/}
            {/*)}*/}
            {!isHostNodeInterface(data) || data.canBeParent ? (
                <>
                    {" "}
                    <Handle
                        type="source"
                        position={Position.Right}
                        style={{background: "#555"}}
                        isConnectable={false}
                    />{" "}
                </>
            ) : (
                <></>
            )}
        </div>
    );
});

export default SystemNode;
