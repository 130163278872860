import React, {FC} from "react";

const NodeName: FC<{
    environment: string;
    nodeId: string;
    variant: string;
    pipelineUrl: string | null;
}> = ({environment, nodeId, variant, pipelineUrl}) => {
    const content = (
        <span className="ellipsis" style={{
                fontSize: "1rem",
                fontWeight: "bold",
                color: "white",
            }}
              title={`${environment}-${variant}`}>
            {environment}-{variant}
        </span>
    );
    if (pipelineUrl) {
        return <a
            href={pipelineUrl}
            target="_blank"
            rel="noopener noreferrer ellipsis"
            className="hover:underline"
        >
            {content}
        </a>
    }
    return content;
};

export default NodeName;
